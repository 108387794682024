import { ReactNode } from "react"

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export type ModalState = {
  Modal: ReactNode
  closeModal: () => void
  openModal: (Modal: ReactNode) => void
}

export type ModalAction = {
  type: 'OPEN_MODAL'
  payload: ReactNode
}
|{
  type: 'CLOSE_MODAL'
}
