import React from 'react'
import type { GatsbyBrowser } from 'gatsby'

//Providers
import ModalProvider from './src/context/Modal/Modal.state'

// Style
import './src/styles/general.scss'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <ModalProvider>{element}</ModalProvider>
