import { createContext } from 'react'
import { ModalState } from './Modal.types'

const initialState: ModalState = {
  Modal: null,
  openModal: ()=> null,
  closeModal: () => null,
}

const ModalContext = createContext<ModalState>(initialState)

export default ModalContext
