import { OPEN_MODAL, ModalAction, ModalState, CLOSE_MODAL } from './Modal.types'

export default (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        Modal: action.payload,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        Modal: null,
      }
    default:
      return state
  }
}
