import React, { ReactNode, useReducer } from 'react'
import PropTypes from 'prop-types'

import ModalContext from './Modal.context'
import ModalReducer from './Modal.reducer'
import { ModalState, CLOSE_MODAL, OPEN_MODAL } from './Modal.types'

interface Props {
  children?: React.ReactNode
}

const ModalProvider: React.FC<Props> = ({ children }) => {
  let initialState: ModalState = {
    Modal: null,
    openModal: () => null,
    closeModal: () => null,
  }

  const [state, dispatch] = useReducer(ModalReducer, initialState)

  const openModal = (Modal: ReactNode) => {
    dispatch({ type: OPEN_MODAL, payload: Modal })
  }

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL })
  }

  initialState = {
    ...initialState,
    openModal,
    closeModal,
  }
  
  return (
    <ModalContext.Provider
      value={{
        Modal: state.Modal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ModalProvider
